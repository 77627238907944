// Receptionist Appointments
export const GET_ALL_PATIENTS_APPOINTMENTS = 'GET_ALL_PATIENTS_APPOINTMENTS';
export const UPDATE_PATIENT_APPOINTMENT = 'UPDATE_PATIENT_APPOINTMENT';
// My Appointments (Patients)
export const GET_ALL_APPOINTMENTS = 'GET_ALL_APPOINTMENTS';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
// Doctors Appointments
export const GET_ALL_DOCTORS_APPOINTMENTS = 'GET_ALL_DOCTORS_APPOINTMENTS';
export const UPDATE_DOCTOR_APPOINTMENT = 'UPDATE_DOCTOR_APPOINTMENT';
export const APPROVE_DOCTOR_APPOINTMENT = 'APPROVE_DOCTOR_APPOINTMENT';
export const DECLINE_DOCTOR_APPOINTMENT = 'DECLINE_DOCTOR_APPOINTMENT';
export const REPLY_TO_APPOINTMENT = 'REPLY_TO_APPOINTMENT';