// All users
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
// Admins
export const GET_ALL_ADMINS = 'GET_ALL_ADMINS';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
// Patients
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
// Doctors
export const GET_ALL_DOCTORS = 'GET_ALL_DOCTORS';
export const GET_A_DOCTOR = 'GET_A_DOCTOR';
export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';