<template>
    <div class="modal">
        <div class="modal-content">
        <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
}
</script>

<style lang="scss" scoped>
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
</style>