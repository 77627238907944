// All users
export const USER_LOGIN_URL = 'login';
export const USER_LOGOUT_URL = 'logout';
export const DELETE_USER_URL = 'users/';
export const CHANGE_PASSWORD_URL = 'users/change-password';
// Admins
export const GET_ALL_ADMINS_URL = 'users/admins';
export const CREATE_ADMIN_URL = 'users/admins';
export const UPDATE_ADMIN_URL = 'users/admins/';
// Patients
export const CREATE_PATIENT_URL = 'users/patients';
export const UPDATE_PATIENT_URL = 'users/patients/';
// Doctors
export const GET_ALL_DOCTORS_URL = 'users/doctors';
export const GET_A_DOCTOR_URL = 'users/doctors/';
export const CREATE_DOCTOR_URL = 'users/doctors';
export const UPDATE_DOCTOR_URL = 'users/doctors/';
// Staff
export const GET_ALL_STAFF_URL = 'staff';
export const ADD_TO_STAFF_URL = 'staff';
export const UPDATE_STAFF_URL = 'staff/';
export const DELETE_STAFF_URL = 'staff/';
// Departments
export const GET_ALL_DEPARTMENTS_URL = 'departments';
export const GET_A_DEPARTMENT_URL = 'departments/';
export const CREATE_DEPARTMENT_URL = 'departments';
export const UPDATE_DEPARTMENT_URL = 'departments/';
export const DELETE_DEPARTMENT_URL = 'departments/';
// Services
export const GET_ALL_SERVICES_URL = 'services';
export const CREATE_SERVICE_URL = 'services';
export const UPDATE_SERVICE_URL = 'services/';
export const DELETE_SERVICE_URL = 'services/';
// Receptionist Appointments
export const GET_ALL_PATIENTS_APPOINTMENTS_URL = 'receptionist-appointments';
export const UPDATE_PATIENT_APPOINTMENT_URL = 'receptionist-appointments/';
// Patients Appointments
export const GET_ALL_APPOINTMENTS_URL = 'my-appointments';
export const CREATE_APPOINTMENT_URL = 'my-appointments';
export const UPDATE_APPOINTMENT_URL = 'my-appointments/';
// Doctors Appointments
export const GET_ALL_DOCTORS_APPOINTMENTS_URL = 'appointments';
export const UPDATE_DOCTOR_APPOINTMENT_URL = 'appointments/';
export const APPROVE_DOCTOR_APPOINTMENT_URL = 'appointments/approve/';
export const DECLINE_DOCTOR_APPOINTMENT_URL = 'appointments/decline/';
export const REPLY_TO_APPOINTMENT_URL = 'appointments/reply';
// Slides
export const GET_ALL_SLIDES_URL = 'slides';
export const CREATE_SLIDE_URL = 'slides';
export const UPDATE_SLIDE_URL = 'slides/';
export const DELETE_SLIDE_URL = 'slides/';
// Updates
export const GET_ALL_UPDATES_URL = 'updates';
export const GET_AN_UPDATE_URL = 'updates/';
export const CREATE_UPDATE_URL = 'updates';
export const UPDATE_UPDATE_URL = 'updates/';
export const DELETE_UPDATE_URL = 'updates/';
// Partners
export const GET_ALL_PARTNERS_URL = 'partners';
export const CREATE_PARTNER_URL = 'partners';
export const UPDATE_PARTNER_URL = 'partners/';
export const DELETE_PARTNER_URL = 'partners/';
// Gallery
export const GET_GALLERY_URL = 'gallery';
export const ADD_TO_GALLERY_URL = 'gallery';
export const UPDATE_GALLERY_URL = 'gallery/';
export const DELETE_GALLERY_URL = 'gallery/';
// Subscribers
export const GET_ALL_SUBSCRIBERS_URL = 'subscribers';
export const CREATE_SUBSCRIBER_URL = 'subscribers';
// Contacts
export const GET_ALL_CONTACTS_URL = 'contacts';
export const CREATE_CONTACT_URL = 'contacts';
export const DELETE_CONTACT_URL = 'contacts/';
export const REPLY_CONTACT_URL = 'contacts/reply';
// Suggestions
export const GET_ALL_SUGGESTIONS_URL = 'suggestions';
export const CREATE_SUGGESTION_URL = 'suggestions';
// Web info
export const GET_ALL_WEB_INFO_URL = 'web-info';
export const UPDATE_WEB_INFO_URL = 'web-info/';
// General
export const GET_ALL_ROLES_URL = 'roles';
export const GET_ALL_DASHBOARD_INFO_URL = 'dashboard-info';
// Files
export const UPLOAD_FILE_URL = 'files';